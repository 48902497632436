<script setup lang="ts">
import { isEmpty } from 'lodash-es';
import { storeToRefs } from 'pinia';
import {
  ref,
  watch,
  computed,
  useIntersectionObserver,
  useHotelCheapest,
  useHotelPath,
  until,
  useScroll,
  useDevice,
} from '#imports';

const { country, currency } = storeToRefs(useConfigStore());
const { locale } = useI18n();
const { getHotelPath } = useHotelPath();
const {
  activeCityRef,
  changeActiveCity,
  getHotelSearchPath,
  trackCheapHotel,
  trackCheapHotelNav,
  trackCheapHotelSeeMore,
} = useHotelCheapest();

const { isCrawler } = useDevice();

const {
  data: cities,
  pending: cityPending,
  execute: fetchCheapestHotelCities,
} = await useAsyncData(
  () =>
    useHotelService().getHotelRecommendationCities({
      country: country.value!,
      lang: locale.value,
    }),
  {
    immediate: isCrawler,
    transform: (data) => data.result.cities,
  },
);

const activeCity = computed(() => activeCityRef.value || (cities.value && cities.value[0]!));

const { data: hotels, pending: hotelPending } = await useAsyncData(
  () =>
    useHotelService().getCheapestHotel({
      city: activeCity.value.id,
      currency: currency.value!,
      lang: locale.value,
    }),
  {
    immediate: isCrawler,
    watch: [currency, activeCity, cities],
    transform: (data) => data.result.hotels,
  },
);

const hotelRecommendationRef = ref<HTMLElement | null>(null);
const currentIndex = ref(0);
const slideRefs = ref<(HTMLElement | null)[]>([]);
const initialState = ref(!isCrawler);
const scrollContainer = ref<HTMLElement | null>(null);

const { isScrolling } = useScroll(scrollContainer);

const disabledNextButton = computed(() => isEmpty(hotels.value) || currentIndex.value === hotels.value!.length - 1);

const disabledPrevButton = computed(() => isEmpty(hotels.value) || currentIndex.value === 0);

watch(hotels, (hotel) => {
  if (hotel) {
    slideRefs.value = Array.from({ length: hotel.length }, () => null);
  }
});

watch(isScrolling, (scrolling) => {
  if (scrolling) trackCheapHotelNav('slide', activeCity.value.name);
});

function nextSlide() {
  if (hotels.value) {
    const slidesLength = hotels.value.length;
    let nextIndex;

    if (currentIndex.value + 1 === slidesLength) nextIndex = slidesLength - 1;
    else if (currentIndex.value + 1 > slidesLength) nextIndex = 0;
    else nextIndex = (currentIndex.value + (currentIndex.value === 0 ? 2 : 1)) % slidesLength;

    scrollToSlide(nextIndex, 'right');
  }
}

function previousSlide() {
  if (hotels.value) {
    const slidesLength = hotels.value.length;
    let prevIndex;

    if (currentIndex.value - 1 < 0) prevIndex = 0;
    else
      prevIndex =
        (currentIndex.value - (currentIndex.value === slidesLength - 1 ? 2 : 1) + slidesLength) % slidesLength;

    scrollToSlide(prevIndex, 'left');
  }
}

function scrollToSlide(index: number, direction: string) {
  const slide = slideRefs.value[index];

  if (slide) {
    const targetElement = slide instanceof HTMLElement ? slide : (slide as any)?.$el;

    if (targetElement && typeof targetElement.scrollIntoView === 'function') {
      targetElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      currentIndex.value = index;

      trackCheapHotelNav('nav', activeCity.value.name, direction);
    }
  }
}

function setSlideRef(index: number, el: HTMLElement | null) {
  (slideRefs.value as (HTMLElement | null)[])[index] = el;
}

const { stop } = useIntersectionObserver(
  hotelRecommendationRef,
  // @ts-expect-error
  async ([{ isIntersecting }]) => {
    if (isIntersecting && !isCrawler) {
      fetchCheapestHotelCities();

      await until(cityPending).toBe(false);

      initialState.value = false;

      stop();
    }
  },
);
</script>

<template>
  <div
    ref="hotelRecommendationRef"
    class="w-full flex flex-col gap-y-15"
  >
    <div class="flex justify-between items-center px-15">
      <h2 class="text-gray-darkest font-bold">
        {{ $t('hotel.smartsavinghotel') }}
      </h2>
      <div class="flex justify-between w-[60px]">
        <ApzButton
          class="!p-0 !w-25 !h-25 flex justify-center items-center !rounded-full shadow-md"
          :disabled="disabledPrevButton"
          @click="previousSlide()"
        >
          <ApzIcon
            class="text-xsmall"
            :icon="['fas', 'chevron-left']"
          />
        </ApzButton>
        <ApzButton
          class="!p-0 !w-25 !h-25 flex justify-center items-center rounded-full shadow-md"
          :disabled="disabledNextButton"
          @click="nextSlide()"
        >
          <ApzIcon
            class="text-xsmall"
            :icon="['fas', 'chevron-right']"
          />
        </ApzButton>
      </div>
    </div>

    <div
      v-if="cityPending || initialState"
      class="w-full overflow-hidden"
    >
      <HotelCityPlaceholderMobile />
    </div>

    <div
      v-else-if="!isEmpty(cities)"
      class="w-full flex gap-10 px-15 whitespace-nowrap overflow-x-auto scrollbar-hide"
    >
      <div
        v-for="(city, index) in cities"
        :key="index"
        :class="{ '!bg-primary !border-white': city.id === activeCity.id }"
        data-testid="hotel-filter-button"
        class="py-10 px-20 border-[0.5px] rounded border-solid border-gray-light bg-whiter"
        @click="
          () => {
            currentIndex = 0;
            changeActiveCity(city);
          }
        "
      >
        <p
          :class="{ '!text-white font-bold': city.id === activeCity.id }"
          class="text-small text-gray-darkest"
        >
          {{ city.name }}
        </p>
      </div>
    </div>

    <div
      v-if="hotelPending || cityPending || initialState"
      class="w-full overflow-hidden"
    >
      <HotelSmartSavingPlaceholderMobile />
    </div>

    <div
      v-else-if="!isEmpty(hotels)"
      ref="scrollContainer"
      class="overflow-x-scroll snap-x snap-mandatory scrollbar-hide w-full px-15"
    >
      <div class="flex gap-x-10">
        <NuxtLink
          v-for="(hotel, index) in hotels"
          :key="index"
          :ref="(el) => setSlideRef(index, (el as HTMLElement))"
          :to="
            getHotelPath(hotel, {
              ci: hotel.checkInDate,
              co: hotel.checkOutDate,
              ro: '1',
              ad: '1',
            })
          "
          external
          no-rel
          class="flex-none !w-[55%] snap-start scroll-mx-15 relative rounded"
          @click="trackCheapHotel(hotel, activeCity)"
        >
          <NuxtImg
            :src="hotel.photo"
            :alt="hotel.name"
            format="webp"
            width="177"
            height="106"
            fit="cover"
            class="aspect-[177/106] w-full rounded object-cover"
            :loading="index > 2 ? 'lazy' : 'eager'"
            sizes="sm:50vw"
            quality="50"
          />

          <div class="absolute inset-0 top-15 bg-gradient-to-t from-[#000000] to-[#69696900] rounded">
            <div class="absolute left-15 bottom-15 flex flex-col gap-y-5 text-small text-white rounded">
              <div class="flex flex-wrap">
                <p class="mr-5">
                  {{ $t('search.from') }}
                </p>
                <p class="font-bold">
                  {{ $c(hotel.price, hotel.currency) }}
                </p>
              </div>

              <p>
                {{ hotel.name }}
              </p>

              <SharedRating
                :rating="hotel.star"
                size="small"
                wrapper-class="flex gap-x-5"
              />
            </div>
          </div>
        </NuxtLink>

        <div class="flex-none w-5" />
      </div>
    </div>

    <div class="px-15">
      <ApzButton
        v-if="activeCity"
        type="primary"
        variant="outline"
        tag="nuxt-link"
        :to="getHotelSearchPath(activeCity)"
        external
        no-rel
        class="w-full !h-35"
        @click="trackCheapHotelSeeMore(activeCity)"
      >
        {{
          $t('hotel.checkitnow', {
            city_name: activeCity?.name ?? '',
          })
        }}
      </ApzButton>
    </div>
  </div>
</template>
